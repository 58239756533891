import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AffiliateProgram from '../../components/AffiliateProgram/AffiliateProgram';
import APStartScreen from '../../components/APStartScreen/APStartScreen';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import StandsFor from '../../components/StandsFor/StandsFor';
import TradeToday_02 from '../../components/TradeToday_02/TradeToday_02';
import './AffiliatesAndPartners.scss';

const AffiliatesAndPartners = () => {
    const [ screenWidth, setScreenWidth ] = useState(0);
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0,0);
    }, [pathname]);

    const handleScroll = () => {
      const position = window.pageYOffset;
    };
    
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    useEffect(() => {
      setScreenWidth(document.documentElement.clientWidth)
    }, []);
    
    window.addEventListener('resize', event => {
      setScreenWidth(document.documentElement.clientWidth)
    }, false);

    return (
        <div className='wrapper'>
          <div className='ap-bg'>
            <div className='nb-desktop'>
              <NavBar selPage={'AffiliatesAndParthners'} /> 
            </div>
            <div className='nb-mobile'>
              <NavBarMobile selPage={'AffiliatesAndParthners'} />
            </div>
            <APStartScreen />
          </div>
          <AffiliateProgram />
          <StandsFor />
          <TradeToday_02 />
          <Footer />
        </div>
    );
};

export default AffiliatesAndPartners;
