import NGCards from './NGCards';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import './NewGeneration.scss';

const NewGeneration = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');

  const cards = [
    { text: t('new_generation_item_1'), image: 'low_trading_fees.png' },
    { text: t('new_generation_item_2'), image: 'welcome_bonus.png' },
    { text: t('new_generation_item_3'), image: 'rapid_execution.png' },
    { text: t('new_generation_item_4'), image: 'customer_support.png' },
    { text: t('new_generation_item_5'), image: 'market_insights.png' },
    { text: t('new_generation_item_6'), image: 'balance_protection.png' },
  ];

    return (
        <div className='ng-container'>
            <div className={`header ${lang !== 'en' ? 'header-anlang' : ''} h-1`}>
              {t('new_generation_title')}
            </div>

            <div className='ng-container-content'>
                {cards.map((card) =>
                    <NGCards card={card} key={card.text} />
                )}
            </div>
        </div>
    );
};

export default NewGeneration;
