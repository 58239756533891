import { useTranslation } from "react-i18next";
import './FormComponent.scss';

const FormComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="position-relative bg-gradient-blue">
      <div className="form__wrap">
        <h2 className="form__title">{t('contact_us_title')}</h2>
        <p className="form__text black">{t('contact_us_text_1')}</p>
        <p className="form__text grey">{t('contact_us_text_2')}</p>
        <form action="#" className="row">
          <div className="col-6">
            <div className="form__group">
              <input placeholder={t('form_fullname')} type="text" className="form-input" />
            </div>
            <div className="form__group">
              <input placeholder={t('form_email')} type="email" className="form-input" />
            </div>
            <div className="form__group">
              <input placeholder={t('form_phone')} type="text" className="form-input" />
            </div>
            <div className="form__group">
              <input placeholder={t('form_country')} type="text" className="form-input" />
            </div>
          </div>
          <div className="col-6">
            <div className="form__group h-100">
              <textarea className="form-input" name="message" cols="30" rows="10" placeholder={t('form_message')}></textarea>
            </div>
          </div>
          <div className="form__group">
            <button className="submit__btn btn-anim-dark" type="submit">{t('send')}</button>
          </div>
        </form>
      </div>
      <div className="form-overlay"></div>
    </div>
  );
};

export default FormComponent;
