import { useRef, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import { gsap } from 'gsap';
import SignUpNow from '../SignUpNow/SignUpNow';
import './APStartScreen.scss';

const APStartScreen = () => {
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <div className='apss-container' ref={ref}>
            <div className='apss-container-content'>
                <div className='apss-header h-1'>
                    {t('ap_start_title')}
                </div>
                <div className='apss-desc'>
                    <div className='apss-desc-header content-5'>
                        {t('ap_start_text_1')}
                    </div>
                    <div className='apss-desc-text'>
                        <div className='apss-desc-text-1 content-1'>
                            {t('ap_start_text_2')}
                        </div>
                        <div className='apss-desc-text-2 content-1'>
                            {t('ap_start_text_3')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='ftr'>
                <SignUpNow />
            </div>
        </div>
    );
};

export default APStartScreen;
