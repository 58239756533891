import { useTranslation } from "react-i18next";
import './AboutCompanyComponent.scss';

const AboutCompanyComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="about-company__container">
      <div className="row">
        <div className="col-6">
          <div className="about-company__wrap">
            <h3 className="about-company__title">{t('about_company_1_title')}</h3>
            <p className="about-company__text">{t('about_company_1_text_1')}</p>
            <p className="about-company__text">{t('about_company_1_text_2')}</p>
          </div>
        </div>
        <div className="col-6">
          <div className="about-company__wrap">
            <h3 className="about-company__title">{t('about_company_2_title')}</h3>
            <p className="about-company__text">{t('about_company_2_text_1')}</p>
          </div>
        </div>
        <div className="col-6">
          <div className="about-company__wrap">
            <h3 className="about-company__title">{t('about_company_3_title')}</h3>
            <p className="about-company__text">{t('about_company_3_text_1')}</p>
            <p className="about-company__text">{t('about_company_3_text_2')}</p>
            <p className="about-company__text">{t('about_company_3_text_3')}</p>
            <a href="https://myaccount.pixpal.tech/signup" className="banner__link btn-anim-dark">{t('open_account')}</a>
          </div>
        </div>
        <div className="col-6">
          <div className="about-company__wrap">
            <h3 className="about-company__title">{t('about_company_4_title')}</h3>
            <p className="about-company__text">{t('about_company_4_text_1')}</p>
            <p className="about-company__text">{t('about_company_4_text_2')}</p>
            <p className="about-company__text">{t('about_company_4_text_3')}</p>
            <p className="about-company__text">{t('about_company_4_text_4')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompanyComponent;
