import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import classNames from 'classnames';
import coin_ethereum from '../../assets/coin_ethereum.png';
import coin_bitcoin from '../../assets/coin_bitcoin.png';
import coin_apple from '../../assets/coin_apple.png';
import coin_facebook from '../../assets/coin_facebook.png';
import coin_tesla from '../../assets/coin_tesla.png';
import { Carousel } from 'react-responsive-carousel';
import Cookies from 'js-cookie';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Swiper.scss';

const Swiper = ({ currentSwipe, setCurrentSwipe, prevSwipe }) => {
    const ref = useRef(null)
    const { t } = useTranslation()
    const lang = Cookies.get('i18next');

    useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
            element.querySelector('#coins-start'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
              },
              {
                opacity: 0,
                y: 0,
                x: 700,
                scale: 1,
                duration: 3,
                ease: "none",
                scrollTrigger: {
                    trigger: element.querySelector(".download-platforms"),
                    start: 350,
                    end: 800,
                    scrub: true
                },
              }
        );
    }, []);

    const coinsPosition = classNames({
        '1-slide': currentSwipe === 1,
        '2-slide': currentSwipe === 2,
        '3-slide': currentSwipe === 3,
    });

    const headerClassname = classNames({
      'swipe-header-en': lang === 'en' || lang === 'cz' ,
      'swipe-header-ru': lang === 'ru' || lang === 'it',
      'swipe-header-es': lang === 'es' || lang === 'de' || lang === 'pl' || lang === 'ro',
    });
    const descClassname = classNames({
      'swipe-description-en': lang === 'en',
      'swipe-description-ru': lang === 'ru' || lang === 'es' || lang === 'it' || lang === 'de' || lang === 'pl' || lang === 'ro' || lang === 'cz' ,
    })

    return (
        <div className='swiper-container' ref={ref}>
            <Carousel
                className='swipes'
                autoPlay={false}
                onChange={(newIndex) => {
                    setCurrentSwipe(newIndex + 1);
                }}
                showThumbs={false}
                showArrows={document.documentElement.clientWidth >= 481 ? true : false}
                swipeable={true}
                showStatus={false}
            >
                <div className='swipe'>
                    <div className={`${headerClassname} h-1 swiper-header`}>
                      {t('slider_title_1')}
                    </div>
                    <div className={`${descClassname} content-1 swiper-desc`}>
                      {t('slider_text_1')}
                    </div>
                </div>
                <div className='swipe'>
                    <div className={`${headerClassname} h-1 swiper-header ${lang === 'es' || lang === 'de' ? 'w-1100' : ''}`}>
                      {t('slider_title_2')}
                    </div>
                    <div className={`${descClassname} content-1 swiper-desc`}>
                      {t('slider_text_2')}
                    </div>
                </div>
                <div className='swipe'>
                    <div className={`${headerClassname} h-1 swiper-header`}>
                      {t('slider_title_3')}
                    </div>
                    <div className={`swipe-header-en h-2 ${headerClassname} second-header`}>
                      {t('slider_text_3')}
                    </div>
                    <div className={`${descClassname} content-1 swiper-desc`}>
                      {t('slider_text_3_1')}
                    </div>
                </div>
            </Carousel>

            <div id='coins-start' className={`coins`}>
                <img className={`coin-start coin-start-facebook coin-start-facebook-${coinsPosition}`} src={coin_facebook} alt='coin_facebook' />
                <img className={`coin-start coin-start-bitcoin coin-start-bitcoin-${coinsPosition}`} src={coin_bitcoin} alt='coin_bitcoin' />
                <img className={`coin-start coin-start-tesla coin-start-tesla-${coinsPosition}`} src={coin_tesla} alt='coin_tesla' />
                <img className={`coin-start coin-start-apple coin-start-apple-${coinsPosition}`} src={coin_apple} alt='coin_apple' />
                <img className={`coin-start coin-start-ethereum coin-start-ethereum-${coinsPosition}`} src={coin_ethereum} alt='coin_ethereum' />
            </div>
        </div>
    );
};

export default Swiper;
