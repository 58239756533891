import { BrowserRouter, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { RouteNames } from '../../router';
import logo from '../../assets/pixpal_logo.png';
import logo_dark from '../../assets/pixpal_logo_dark.png';
import arrow from '../../assets/arrow.png';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import variables from './NavBar.scss';
import i18n from "i18next";
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const languages = [
    { value: 'ru', label: 'Ru' },
    { value: 'en', label: 'En' },
    { value: 'es', label: 'Es' },
    { value: 'it', label: 'It' },
    { value: 'de', label: 'De' },
    { value: 'pl', label: 'Pl' },
    { value: 'ro', label: 'Ro' },
    { value: 'cz', label: 'Cz' },
];

const NavBar = ({ content, selPage }) => {
    const router = useNavigate();
    const { t } = useTranslation();
    const [ selectedPage, setSelectedPage ] = useState('StartTrading');
    const [ defaultOption, setDefaultOption ] = useState(Cookies.get('i18next'));

    useEffect(() => {
        setDefaultOption(Cookies.get('i18next'));
    }, [])
    useEffect(() => {
        setSelectedPage(selPage);
    }, []);

    useEffect(() => {
        if (content === 'dark') {
            document.documentElement.style.setProperty('--bgColor', '#3F778A');
            document.documentElement.style.setProperty('--color', 'white');
            document.documentElement.style.setProperty('--login-color', 'black');
            document.documentElement.style.setProperty('--login-bg', 'white');
            document.documentElement.style.setProperty('--login-box-shadow', '0px 4px 6px #2F5C6B');
        } else {
            document.documentElement.style.setProperty('--bgColor', 'transperent');
            document.documentElement.style.setProperty('--color', '#000000');
            document.documentElement.style.setProperty('--login-color', 'white');
            document.documentElement.style.setProperty('--login-bg', '#3F778A');
            document.documentElement.style.setProperty('--login-box-shadow', 'none');
        }
    }, []);

    return (
        <div className='navbar'>
            <div className='navbar-logo'>
                <img
                    className='navbar-logo-icon'
                    height={50}
                    src={content === 'dark' ? logo : logo_dark}
                    alt='logo'
                    onClick={() => router(RouteNames.MAIN)}
                />
            </div>

            <div className='navbar-content'>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.MAIN} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'StartTrading' ? 'selected-page' : ''}
                        `}
                    >{t('start_trading')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.TRADING_PLATFORMS} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'TradingPlatform' ? 'selected-page' : ''}
                        `}
                    >{t('trading_platforms')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.EARNINGS_CALENDAR} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'EarningsCalendar' ? 'selected-page' : ''}
                        `}
                    >{t('earnings_calendar')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.EDU_AND_TOOLS} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'EduAndTools' ? 'selected-page' : ''}
                        `}
                    >{t('edu_tools')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.AFILLIATES_AND_PARTNERS} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'AffiliatesAndParthners' ? 'selected-page' : ''}
                        `}
                    >{t('affiliates_partners')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.ABOUT_US} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'AboutUs' ? 'selected-page' : ''}
                        `}
                    >{t('about_us')}</Link>
                </div>
                <div className='navbar-content-item content-1'> 
                    <Link 
                        to={RouteNames.COMPANY_SUPPORT} 
                        className={`
                            decoration-none ${content === 'dark' ? 'c-white' : 'c-black'} 
                            ${selectedPage === 'CompanyAndSupport' ? 'selected-page' : ''}
                        `}
                    >{t('company_support1')}</Link>
                </div>
            </div>

            <div className='navbar-tools'>
                <div className={`navbar-tools-item lang`}>
                    <Dropdown 
                        placeholderClassName={content === 'dark' ? 'c-white' : 'c-black'} 
                        options={languages} 
                        onChange={(e) => i18n.changeLanguage(e.value)} 
                        value={defaultOption} 
                        placeholder='EN' 
                    />
                </div>
                <a 
                    href='https://myaccount.pixpal.tech/login' 
                    className={`decoration-none navbar-tools-item login ${content === 'dark' ? 'btn-anim' : 'btn-anim-dark'}`}
                >
                    {t('login')}
                </a>
            </div>
        </div>
    );
};

export default NavBar;
