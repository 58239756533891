import NavBar from '../../components/NavBar/NavBar';
import './StartTrading.scss';

const StartTrading = () => {
    return (
        <div>
            <NavBar />
            <div>StartTrading</div>
        </div>
    );
};

export default StartTrading;
