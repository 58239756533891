import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import google_play from '../../assets/download_google_play.png';
import apple from '../../assets/download_apple.png';
import phones from '../../assets/mob-phones.png';
import graph from '../../assets/chart.png';
import './TradeAnywhere.scss';
import {useTranslation} from "react-i18next";

const TradeAnywhere = () => {
    const { t } = useTranslation()
    const ref = useRef(null);
    var screenWidth = document.documentElement.clientWidth;


    /**
     *          ========     PHONES       ========
    */

    // ENTER

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-phones'),
                {
                    opacity: 0,
                    scale: 0.6,
                    y: -100,
                    x: -100,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    // scrollTrigger: {
                    //     trigger: element.querySelector(".ta-container-header"),
                    //     start: "top bottom",
                    //     end: "top center",
                    //     scrub: true,
                    // },
                    scrollTrigger: {
                        trigger: element.querySelector("#asdsd"),
                        start: "top bottom",
                        end: "top center",
                        scrub: true,
                    },
                }
            );
        } else {
            var scrollStart;

            if (screenWidth <= 1024 && screenWidth >= 801)
                scrollStart = 6700;
            if (screenWidth <= 800 && screenWidth >= 601)
                scrollStart = 6999;
            if (screenWidth <= 600 && screenWidth >= 481)
                scrollStart = 7199;
            if (screenWidth <= 480 && screenWidth >= 0)
                scrollStart = 6500;


            gsap.fromTo(
                element.querySelector('.ta-container-images-phones'),
                {
                    opacity: 0,
                    scale: 0.6,
                    y: -600,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".ta-container-content"),
                        // start: "bottom bottom",
                        start: scrollStart,
                    },
                }
            );
        }
    }, []);


    // EXIT

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-phones'),
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                },
                {
                    opacity: 0,
                    y: -100,
                    x: 500,
                    scale: 0.2,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".download"),
                        start: "bottom center",
                        end: "bottom top",
                        scrub: true
                    },
                }
            );
        }
    }, []);



    /**
     *          ========     GRAPH       ========
    */

    // ENTER

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 0,
                    scale: 0.6,
                    y: -300,
                    x: 50,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    // scrollTrigger: {
                    //     trigger: element.querySelector(".download"),
                    //     start: "top bottom",
                    //     end: "top center",
                    //     scrub: true
                    // },
                    scrollTrigger: {
                        trigger: element.querySelector(".ta-container-header"),
                        start: "top bottom",
                        end: "top center",
                        scrub: true
                    },
                }
            );
        } else {
            var scrollStart;

            if (screenWidth <= 1024 && screenWidth >= 800)
                scrollStart = 6700;
            if (screenWidth <= 799 && screenWidth >= 601)
                scrollStart = 6999;
            if (screenWidth <= 600 && screenWidth >= 481)
                scrollStart = 7199;
            if (screenWidth <= 480 && screenWidth >= 0)
                scrollStart = 6500;

            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 0,
                    // scale: 0.6,
                    y: -500,
                    x: 50,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    // scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".ta-container-content"),
                        // start: "bottom bottom",
                        start: scrollStart,
                    },
                }
            );
        }
    }, []);


    // MIDDLE

    useEffect(() => {
        const element = ref.current;

        if (screenWidth >= 1281) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: 750,
                    x: 70,
                    duration: 1,
                    ease: "none",
                    // scrollTrigger: {
                    //     trigger: element.querySelector(".download"),
                    //     start: "bottom center",
                    //     end: "top top",
                    //     scrub: true
                    // },
                    scrollTrigger: {
                        trigger: element.querySelector(".download"),
                        start: 6000,
                        end: 6300,
                        scrub: true
                    },
                }
            );
        }

        // ????

        if (screenWidth <= 1280 && screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: 750,
                    x: 70,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".download"),
                        start: "bottom center",
                        end: "bottom top",
                        scrub: true
                    },
                }
            );
        }

        if (screenWidth <= 1024 && screenWidth >= 952) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                },
                {
                    opacity: 1,
                    y: 800,
                    x: 350,
                    scale: 0.5,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".ta-container-images"),
                        // start: "center top",
                        start: 7599,
                    },
                }
            );
        }

        if (screenWidth <= 951 && screenWidth >= 481) {
            gsap.fromTo(
                element.querySelector('.ta-container-images-graph'),
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                },
                {
                    opacity: 0,
                    y: 300,
                    x: 0,
                    scale: 0.8, // SCALE 0
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".ta-container-images"),
                        // start: "center top",
                        start: 8000,
                    },
                }
            );
        }

        // if (screenWidth <= 480 && screenWidth >= 0) {
        //     gsap.fromTo(
        //         element.querySelector('.ta-container-images-graph'),
        //         {
        //             opacity: 1,
        //             y: 0,
        //             x: 0,
        //             scale: 1,
        //         },
        //         {
        //             opacity: 0,
        //             y: 300,
        //             x: 0,
        //             scale: 0.8, // SCALE 0
        //             duration: 1,
        //             ease: "none",
        //             scrollTrigger: {
        //                 trigger: element.querySelector(".ta-container-images"),
        //                 // start: "center top",
        //                 start: 7500,
        //             },
        //         }
        //     );
        // }
    }, []);


    // EXIT

    // useEffect(() => {
    //     const element = ref.current;

    //     if (screenWidth >= 1281) {
    //         gsap.fromTo(
    //             element.querySelector('.ta-container-images-graph'),
    //             {
    //                 y: 750,
    //                 x: 70,
    //                 // scale: 0.5,
    //             },
    //             {
    //                 opacity: 0,
    //                 y: 1200,
    //                 x: 1000,
    //                 duration: 1,
    //                 ease: "none",
    //                 scrollTrigger: {
    //                     trigger: element.querySelector(".download"),
    //                     start: 6350,
    //                     end: 6600,
    //                     scrub: true
    //                 },
    //             }
    //         );
    //     }

    //     if (screenWidth <= 1280 && screenWidth >= 431) {
    //         gsap.fromTo(
    //             element.querySelector('.ta-container-images-graph'),
    //             {
    //                 y: 750,
    //                 x: 70,
    //                 // scale: 0.5,
    //             },
    //             {
    //                 opacity: 0,
    //                 y: 1200,
    //                 x: 1000,
    //                 duration: 1,
    //                 ease: "none",
    //                 scrollTrigger: {
    //                     trigger: element.querySelector(".download"),
    //                     start: 6900,
    //                     end: 7200,
    //                     scrub: true
    //                 },
    //             }
    //         );
    //     }
    // }, []);


    return (
        <div className='ta-container' ref={ref}>

            <div className='ta-container-wrapper'>
                <div className='ta-container-header h-1' id='asdsd'>
                    {t('trade_anywhere_title')}
                </div>

                <div className='ta-container-content'>
                    <div className='ta-container-content-first'>
                        {/* <div className='par content-1'>
                            {t('trade_anywhere_text_1')}
                        </div> */}
                        <div className='par content-1'>
                            {t('trade_anywhere_text_2')}
                        </div>
                        <div className='par content-1'>
                            {t('trade_anywhere_text_3')}
                        </div>
                    </div>

                    <div className='ta-container-content-second'>
                        <div className='par content-1'>
                            {t('trade_anywhere_text_4')}
                        </div>

                        <div className='download'>
                            <a href='https://apps.apple.com/us/app/utip-mobile/id1069332482'>
                                <img src={apple} alt='apple' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=universal.trading.information.platform'>
                                <img src={google_play} alt='google play' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ta-container-images'>
                <img className='ta-container-images-phones' src={phones} alt='phones' />
                <img className='ta-container-images-graph' src={graph} alt='graph' />
            </div>
        </div>
    );
};

export default TradeAnywhere;
