import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import './AffiliateProgram.scss';

const Card = ({ card }) => {
  const { t } = useTranslation()
    const imageURL = `./assets/${card.image}.png`;
    const imageURLWhite = `./assets/${card.image}_white.png`;
    const lang = Cookies.get('i18next');
    return (
        <a className={`decoration-none ap-card height-${lang}`} href='https://myaccount.pixpal.tech/signup'>
            <div className='ap-card-icon'>
                <img id='img-dark' src={imageURL} alt='icon' />
                <img id='img-white' src={imageURLWhite} alt='' />
            </div>

            <div className='ap-card-content'>
                <div className='ap-card-content-header content-5'>
                    {card.header}
                </div>
                <div className='ap-card-content-text-1 content-1'>
                    {card.text_1}
                </div>
                <div className='ap-card-content-text-2 content-1'>
                    {card.text_2}
                </div>
                <div className='ap-card-content-text-3 content-4'>
                    {card.text_3}
                </div>
            </div>

            <a href='https://myaccount.pixpal.tech/signup' className='decoration-none ap-card-btn content-1'>
              {t('sign_up_now')}
            </a>
        </a>
    );
};

export default Card;
