import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import apple from '../../assets/apple_blue.png'
import android from '../../assets/android_blue.png'
import windows from '../../assets/windows_blue.png'
import map from '../../assets/Map.png';
import coin_ethereum from '../../assets/coin_ethereum.png';
import coin_bitcoin from '../../assets/coin_bitcoin.png';
import coin_apple from '../../assets/coin_apple.png';
import coin_facebook from '../../assets/coin_facebook.png';
import coin_tesla from '../../assets/coin_tesla.png';
import './OnlineCFD.scss';

const OnlineCFD = () => {
    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    var screenWidth = document.documentElement.clientWidth;
    const { t } = useTranslation()


    /**
     *          ========     MAP       ========
    */

    useEffect(() => {
        const element = ref.current;

        if (document.documentElement.clientWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('#online-cfd-map'),
                {
                    opacity: 0,
                    scale: 0.2,
                    // y: -20
                  },
                  {
                    opacity: 1,
                    // y: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                      trigger: element.querySelector(".header"),
                      start: "top bottom",
                      end: "top center",
                      scrub: true,
                    },
                  }
            );
        } else {
            gsap.fromTo(
                element.querySelector('#online-cfd-map'),
                {
                    opacity: 0,
                    scale: 0.2,
                    y: -500
                },
                {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".map-glow"),
                        start: "top bottom",
                    },
                }
            );
        }
    }, []);



    /**
     *          ========     COINS       ========
    */

    // ENTER

    useEffect(() => {
        const element = ref.current;

        if (document.documentElement.clientWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 0,
                    scale: 0.6,
                    y: -1000,
                    x: 700,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 3,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".header"),
                        start: "top bottom",
                        end: "top center",
                        scrub: true,
                    },
                }
            );
        } else {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 0,
                    scale: 0.6,
                    y: -1000,
                    x: 700,
                },
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".map-glow"),
                        start: "top bottom",
                        // end: "center center",
                        // scrub: true
                    },
                }
            );
        }
    }, []);

    // MIDDLE

    useEffect(() => {
        const element = ref.current;

        var yy, xx;

        if (screenWidth >= 1261) {
            yy = 700;
            xx = 60
        }
        if (screenWidth <= 1260 && screenWidth >= 1025) {
            yy = 750;
            xx = 0;
        }
        if (screenWidth <= 1024 && screenWidth >= 482) {
            yy = 1000;
            xx = 0;
        }
        if (screenWidth <= 481 && screenWidth >= 0) {
            yy = 750;
            xx = 0;
        }

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: yy,
                    x: xx,
                    scale: 1,
                    duration: 3,
                    ease: "none",
                    // scrollTrigger: {
                    //     trigger: element.querySelector(".download-text"),
                    //     start: "top center",
                    //     end: "top top",
                    //     scrub: true
                    // },
                    scrollTrigger: {
                        trigger: element.querySelector("#coins-middle-trigger"),
                        start: "top center",
                        end: "top top",
                        scrub: true
                    },
                }
            );
        }

        if (screenWidth <= 1024 && screenWidth >= 481) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: yy,
                    x: xx,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".map-glow"),
                        // start: "top top",
                        start: 2799,
                    },
                }
            );
        }

        if (screenWidth <= 480 && screenWidth >= 0) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                    x: 0,
                },
                {
                    opacity: 1,
                    y: yy,
                    x: xx,
                    scale: 1,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".map-glow"),
                        // start: "top top",
                        start: 2699,
                    },
                }
            );
        }
    }, []);

    // EXIT

    useEffect(() => {
        const element = ref.current;

        var yy, xx;

        if (screenWidth >= 1261) {
            yy = 700;
            xx = 60
        }
        if (screenWidth <= 1260 && screenWidth >= 1025) {
            yy = 750;
            xx = 0;
        }
        if (screenWidth <= 1024 && screenWidth >= 482) {
            yy = 1000;
            xx = 0;
        }
        if (screenWidth <= 481 && screenWidth >= 0) {
            yy = 750;
            xx = 0;
        }

        if (screenWidth >= 1025) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: yy,
                    x: xx,
                },
                {
                    opacity: 0,
                    y: 1100,
                    x: 700,
                    scale: 0.5,
                    duration: 3,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".download-platforms"),
                        start: 2720,
                        end: 3200,
                        scrub: true
                    },
                }
            );
        }

        if (screenWidth <= 1024 && screenWidth >= 482) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: yy,
                    x: xx,
                },
                {
                    opacity: 0,
                    y: 1400,
                    x: 700,
                    scale: 0.5,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".download-platforms"),
                        start: 3900,
                    },
                }
            );
        }

        if (screenWidth <= 481 && screenWidth >= 0) {
            gsap.fromTo(
                element.querySelector('#online-cfd-coins'),
                {
                    opacity: 1,
                    scale: 1,
                    y: yy,
                    x: xx,
                },
                {
                    opacity: 0,
                    y: 1400,
                    x: 700,
                    scale: 0.5,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element.querySelector(".download-platforms"),
                        start: 3500,
                    },
                }
            );
        }
    }, []);



    /**
     *          ========     SINGLE COINS       ========
    */


    // FB

    useEffect(() => {
        const element = ref.current;
        var xx, yy, sscale;

        if (screenWidth >= 482) {
            xx = 70
            yy = 0;
            sscale = 0.8;
        }
        if (screenWidth <= 481) {
            xx = 20;
            yy = 0;
            sscale = 0.6;
        }

        gsap.fromTo(
            element.querySelector('#coin-facebook'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
            },
            {
                opacity: 1,
                y: yy,
                x: xx,
                scale: sscale,
                duration: 3,
                ease: "none",
                // scrollTrigger: { trigger: element.querySelector(".download-platforms"), start: "top center", end: "top top", scrub: true },
                scrollTrigger: {
                    trigger: element.querySelector("#coins-middle-trigger"),
                    start: "top center",
                    end: "top top",
                    scrub: true
                },
            }
        );
    }, []);


    // BTC

    useEffect(() => {
        const element = ref.current;
        var xx, yy, sscale;

        if (screenWidth >= 482) {
            xx = 10;
            yy = 0;
            sscale = 0.8;
        }
        if (screenWidth <= 481) {
            xx = 20;
            yy = 0;
            sscale = 0.7;
        }

        gsap.fromTo(
            element.querySelector('#coin-bitcoin'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
            },
            {
                opacity: 1,
                y: yy,
                x: xx,
                scale: sscale,
                duration: 3,
                ease: "none",
                // scrollTrigger: { trigger: element.querySelector(".download-platforms"), start: "top center", end: "top top", scrub: true },
                scrollTrigger: {
                    trigger: element.querySelector("#coins-middle-trigger"),
                    start: "top center",
                    end: "top top",
                    scrub: true
                },
            }
        );
    }, []);


    // TESLA

    useEffect(() => {
        const element = ref.current;
        var xx, yy, sscale;

        if (screenWidth >= 482) {
            xx = 10;
            yy = 50;
            sscale = 0.8;
        }
        if (screenWidth <= 481) {
            xx = -10;
            yy = 20;
            sscale = 0.9;
        }

        gsap.fromTo(
            element.querySelector('#coin-tesla'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
            },
            {
                opacity: 1,
                y: yy,
                x: xx,
                scale: sscale,
                duration: 3,
                ease: "none",
                // scrollTrigger: { trigger: element.querySelector(".download-platforms"), start: "top center", end: "top top", scrub: true },
                scrollTrigger: {
                    trigger: element.querySelector("#coins-middle-trigger"),
                    start: "top center",
                    end: "top top",
                    scrub: true
                },
            }
        );
    }, []);


    // ETH

    useEffect(() => {
        const element = ref.current;
        var xx, yy, sscale;

        if (screenWidth >= 482) {
            xx = -50;
            yy = -50;
            sscale = 0.8;
        }
        if (screenWidth <= 481) {
            xx = -30;
            yy = -30;
            sscale = 0.7;
        }

        gsap.fromTo(
            element.querySelector('#coin-ethereum'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
            },
            {
                opacity: 1,
                y: yy,
                x: xx,
                scale: sscale,
                duration: 3,
                ease: "none",
                // scrollTrigger: { trigger: element.querySelector(".download-platforms"), start: "top center", end: "top top", scrub: true },
                scrollTrigger: {
                    trigger: element.querySelector("#coins-middle-trigger"),
                    start: "top center",
                    end: "top top",
                    scrub: true
                },
            }
        );
    }, []);


    // AAPL

    useEffect(() => {
        const element = ref.current;
        var xx, yy, sscale;

        if (screenWidth >= 482) {
            xx = -40;
            yy = 40;
            sscale = 0.8;
        }
        if (screenWidth <= 481) {
            xx = -10;
            yy = 0;
            sscale = 0.9;
        }

        gsap.fromTo(
            element.querySelector('#coin-apple'),
            {
                opacity: 1,
                scale: 1,
                y: 0,
                x: 0,
            },
            {
                opacity: 1,
                y: yy,
                x: xx,
                scale: sscale,
                duration: 3,
                ease: "none",
                // scrollTrigger: { trigger: element.querySelector(".download-platforms"), start: "top center", end: "top top", scrub: true },
                scrollTrigger: {
                    trigger: element.querySelector("#coins-middle-trigger"),
                    start: "top center",
                    end: "top top",
                    scrub: true
                },
            }
        );
    }, []);


    return (
        <div className='online-cfd-container' ref={ref}>
            <div className='h-1 header'><Trans>{t('online_cfd')}</Trans></div>
            <div className='online-cfd-container-content'>
                <div className='info'>
                    <div className='description'>
                        <div className='description-first'>
                            <div className='content-1 description-block' >
                                {t('online_cfd_text_1')}
                            </div>
                            <div className='content-1 description-block' >
                                {t('online_cfd_text_2')}
                            </div>
                            <div className='content-1 description-block' >
                                {t('online_cfd_text_3')}
                            </div>
                        </div>
                        <div className='description-second'>
                            <div className='content-1 description-block' >
                                {t('online_cfd_text_4')}
                            </div>
                            <div className='content-1 description-block' id='coins-middle-trigger' >
                                {t('online_cfd_text_5')}
                            </div>
                            <div className='content-4 download'>
                                <div className='download-text'>{t('online_cfd_subtitle')}</div>
                                <div className='download-platforms'>
                                    {/* <img className='download-platforms-item' src={utip} alt='utip' /> */}
                                    <img className='download-platforms-item' src={apple} alt='utip' />
                                    <img className='download-platforms-item' src={android} alt='utip' />
                                    <img className='download-platforms-item' src={windows} alt='utip' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='map-glow'>
                    <img id='online-cfd-map' className='map' src={map} alt='map' />

                    <div id='online-cfd-coins'>
                        <img id='coin-facebook' className='coin-facebook' src={coin_facebook} alt='coin_facebook' />
                        <img id='coin-bitcoin' className='coin-bitcoin' src={coin_bitcoin} alt='coin_bitcoin' />
                        <img id='coin-tesla' className='coin-tesla' src={coin_tesla} alt='coin_tesla' />
                        <img id='coin-ethereum' className='coin-ethereum' src={coin_ethereum} alt='coin_ethereum' />
                        <img id='coin-apple' className='coin-apple' src={coin_apple} alt='coin_apple' />
                    </div>
                </div>

            </div>


        </div>
    );
};

export default OnlineCFD;
