import { useTranslation } from "react-i18next";
import './AffiliateProgram.scss';
import Card from './Card';


const AffiliateProgram = () => {
    const { t } = useTranslation()
    const cards = [
        {
            image: 'cpa_affiliates',
            header: t('affiliate_prog_card_1_title_1'),
            text_1: t('affiliate_prog_card_1_text_1'),
            text_2: t('affiliate_prog_card_1_text_2'),
            text_3: t('affiliate_prog_card_1_text_3'),
        },
        {
            image: 'hybrid_program',
            header: t('affiliate_prog_card_2_title_1'),
            text_1: t('affiliate_prog_card_2_text_1'),
            text_2: t('affiliate_prog_card_2_text_2'),
            text_3: t('affiliate_prog_card_2_text_3'),
        },
        {
            image: 'introducing_broker',
            header: t('affiliate_prog_card_3_title_1'),
            text_1: t('affiliate_prog_card_3_text_1'),
            text_2: t('affiliate_prog_card_3_text_2'),
            text_3: t('affiliate_prog_card_3_text_3'),
        },
    ];
    return (
        <div className='ap-container'>
            <div className='ap-container-header h-1'>
                {t('affiliate_prog_title')}
            </div>
            <div className='ap-container-content'>
                {cards.map((card, index) =>
                    <Card card={card} key={index} />
                )}
            </div>
        </div>
    );
};

export default AffiliateProgram;
