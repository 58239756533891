import { useState } from 'react';
import { useTranslation } from "react-i18next";
import line_bg from '../../assets/Line-BG.png';
import line_bg_blue from '../../assets/line_bg_blue.png';
import Swiper from '../Swiper/Swiper';
import SsPlanet from '../ssPlanet/SsPlanet';
import ProTrader from '../../assets/ProTrader.png';
import WebTracker from '../../assets/WebTracker.png';
import ICPInvestApp from '../../assets/ICPInvestApp.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './StartScreen.scss';
import NavBar from '../NavBar/NavBar';
import { useEffect } from 'react';
import SignUpNow from '../SignUpNow/SignUpNow';

const StartScreen = ({ content }) => {
    const [ currentSwipe, setCurrentSwipe ] = useState(1);
    const { t } = useTranslation()

    useEffect(() => {
        if (content === 'swiper') {
            document.documentElement.style.setProperty('--ss-bg', 'rgba(63, 119, 138, 1)');
            document.documentElement.style.setProperty('--live-acc-color', '#000000');
            document.documentElement.style.setProperty('--live-acc-bg', 'white');
            document.documentElement.style.setProperty('--live-acc-shadow', '0px 4px 6px #2F5C6B');
        } else {
            document.documentElement.style.setProperty('--ss-bg', 'transperent');
            document.documentElement.style.setProperty('--live-acc-color', 'white');
            document.documentElement.style.setProperty('--live-acc-bg', '#3F778A');
            document.documentElement.style.setProperty('--live-acc-shadow', 'none');
        }
    }, []);

    return (
        <div className='ss-container'>
            {content === 'swiper' ?
                <div className='ss-container-swiper'>
                        <Swiper
                            currentSwipe={currentSwipe}
                            setCurrentSwipe={setCurrentSwipe}
                        />
                </div>
                :
                <div className='ss-container-swiper'>
                    <SsPlanet />
                </div>
            }

            {content === 'swiper' ?
                <div className='ss-container-buttons'>
                    <a href='https://myaccount.pixpal.tech/signup' className='live-account unselectable btn-anim'>{t('live_account')}</a>
                </div>
                :
                <div className='ss-container-buttons'>
                    <a href='https://myaccount.pixpal.tech/signup' className='decoration-none live-account unselectable btn-anim-dark' style={{width: '160px'}}>{t('trade_now')}</a>
                </div>
            }
            <div className='ss-container-footer'>
                <div className='ss-container-footer-links'></div>
            </div>
            {content !== 'swiper' &&
                <div className='ss-container-sgn'>
                    <SignUpNow />
                </div>
            }
        </div>
    );
};

export default StartScreen;
