import './SignUpNow.scss';
import { Trans, useTranslation } from "react-i18next";

const SignUpNow = () => {
    const { t } = useTranslation()
    return (
        <div className='sun-container'>
            <div className='sun-container-content'>
                <div className='sun-container-content-item'>
                    <div className='main-text h-2'>100K+</div>
                    <div className='second-text content-2'>{t('clients')}</div>
                </div>
                <div className='sun-container-content-item'>
                    <div className='main-text h-2'>3K+</div>
                    <div className='second-text content-2'>{t('employees')}</div>
                </div>
                <div className='sun-container-content-item'>
                    <div className='main-text h-2'>$200+</div>
                    <div className='second-text content-2' style={{width: '140px'}}><Trans>{t('billion_of_monthly')}</Trans></div>
                </div>
                <div className='sun-container-content-item'>
                    <div className='main-text h-2'>2K+</div>
                    <div className='second-text content-2'><Trans>{t('tradable_instruments')}</Trans></div>
                </div>
            </div>
            <a href='https://myaccount.pixpal.tech/signup' className='decoration-none sun-container-btn btn-anim-dark'>
                {t('sign_up_now')}
            </a>
        </div>
    );
};

export default SignUpNow;
