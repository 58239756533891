import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import gsap from 'gsap';
import * as Scroll from 'react-scroll';
import './ETStartScreen.scss';
const Link = Scroll.Link;

const ETStartScreen = ({ selectedTab, setSelectedTab }) => {
    const ref = useRef(null);
    const { t } = useTranslation()

    return (
        <div className='etss-container' ref={ref}>
            <div className='etss-container-first'>
                <div className='etss-container-first-header h-1'>
                    {t('et_start_title_1')}
                </div>
                <div className='etss-container-first-text content-5'>
                    {t('et_start_title_2')}
                </div>
                <a href='https://myaccount.pixpal.tech/signup' className='decoration-none etss-container-first-btn btn-anim-dark'>
                    {t('open_account')}
                </a>
            </div>
            <div className='etss-container-second'>
                <div className='etss-container-second-header'>
                    {t('et_start_table_1')}
                </div>
                <div className='etss-container-second-links'>
                    <Link to='what_is_forex' smooth={true} offset={-50}>
                        <div className='etss-container-second-links-item content-5'>
                            {t('et_start_table_2')}
                        </div>
                    </Link>
                    <Link to='fund_analysis' smooth={true} offset={-50}>
                        <div className='etss-container-second-links-item content-5'>
                            {t('et_start_table_3')}
                        </div>
                    </Link>
                    <Link to='fund_analysis' smooth={true} offset={70} onClick={() => setSelectedTab(1)}>
                        <div className='etss-container-second-links-item content-5'>
                            {t('et_start_table_4')}
                        </div>
                    </Link>
                    <Link to='fund_analysis' smooth={true} offset={70} onClick={() => setSelectedTab(2)}>
                        <div className='etss-container-second-links-item content-5'>
                            {t('et_start_table_5')}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ETStartScreen;
