import { useTranslation } from "react-i18next";
import steps from '../../assets/steps.png';
import bages from '../../assets/bages.png';
import chart from '../../assets/chart.png';
import './TradeToday_02.scss';

const TradeToday_02 = () => {
    const { t } = useTranslation()
    return (
        <div className='tt2-container'>
            <div className='tt2-container-header h-1'>
                {t('trade_today_title')}
            </div>

            <div className='tt2-container-content'>
                <div className='tt2-container-content-steps'>
                    <div className='steps-image'>
                        <img src={steps} alt='steps' />
                    </div>
                    <div className='steps-text'>
                        <div className='steps-text-par'>
                            <div className='steps-text-par-header content-3'>{t('sign_up_now')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_1')}</div>
                        </div>
                        <div className='steps-text-par par2'>
                            <div className='steps-text-par-header content-3'>{t('make_deposit')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_2')}</div>
                        </div>
                        <div className='steps-text-par par3'>
                            <div className='steps-text-par-header content-3'>{t('start_trading_session')}</div>
                            <div className='steps-text-par-desc content-1'>{t('trade_today_step_3')}</div>
                        </div>
                    </div>
                </div>

                <div className='tt2-container-content-bages'>
                    <img id='bages' src={bages} alt='bages' />
                    <img id='chart' src={chart} alt='chats' />
                </div>
            </div>

            <div className='tt2-container-footer'>
                <div className='tt2-container-footer-text content-5'>
                    {t('welcome_universe_trader')}
                </div>
                <a href='https://myaccount.pixpal.tech/signup' className='decoration-none tt2-container-footer-button content-1 btn-anim-dark'>
                    {t('open_account')}
                </a>
            </div>
        </div>
    );
};

export default TradeToday_02;
