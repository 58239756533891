import { useTranslation } from 'react-i18next';
import './ECStartScreen.scss';

const ECStartScreen = () => {
    const { t } = useTranslation();
    return (
        <div className='ecss-container'>
            <div className='ecss-container-header'>
                {t('quarterly_reports')}
            </div>
            <div className='ecss-container-desc'>
            {t('quarterly_reports_desc')}
            </div>
            <a className='ecss-container-btn btn-anim-dark decoration-none' href='https://myaccount.pixpal.tech/signup'>
                {t('open_account')}
            </a>
        </div>
    );
};

export default ECStartScreen;
