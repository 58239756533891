import { useState, useEffect } from 'react';
import ETStartScreen from '../../components/ETStartScreen/ETStartScreen';
import Footer from '../../components/Footer/Footer';
import FundamentalAnalysis from '../../components/FundamentalAnalysis/FundamentalAnalysis';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import TradeToday_02 from '../../components/TradeToday_02/TradeToday_02';
import WhatIsForex from '../../components/WhatIsForex/WhatIsForex';
import { useLocation } from 'react-router-dom';
import './EduAndTools.scss';

const EduAndTools = () => {
    const [ screenWidth, setScreenWidth ] = useState(0);
    const [ selectedTab, setSelectedTab ] = useState(0);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);  

    useEffect(() => {
        setScreenWidth(document.documentElement.clientWidth)
    }, []);
    
    window.addEventListener('resize', event => {
        setScreenWidth(document.documentElement.clientWidth)
    }, false);

    return (
        <div className='wrapper'>
            <div className='et-bg'>
                <div className='nb-desktop'>
                    <NavBar selPage={'EduAndTools'} /> 
                </div>
                <div className='nb-mobile'>
                    <NavBarMobile selPage={'EduAndTools'}/>
                </div>
                <ETStartScreen selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            </div>
            <WhatIsForex />
            <FundamentalAnalysis selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <TradeToday_02 />
            <Footer />
        </div>
    );
};

export default EduAndTools;
