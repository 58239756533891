import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import './OurValuesComponent.scss';
import settings1 from '../../../assets/about-us/settings-1.png'
import settings2 from '../../../assets/about-us/settings-2.png'
import settings3 from '../../../assets/about-us/settings-3.png'
import settings4 from '../../../assets/about-us/settings-4.png'
import settings5 from '../../../assets/about-us/settings-5.png'
import settings6 from '../../../assets/about-us/settings-6.png'
import icon1 from '../../../assets/about-us/settings-1-icon.png'
import icon2 from '../../../assets/about-us/settings-2-icon.png'
import icon3 from '../../../assets/about-us/settings-3-icon.png'
import icon4 from '../../../assets/about-us/settings-4-icon.png'
import icon5 from '../../../assets/about-us/settings-5-icon.png'
import icon6 from '../../../assets/about-us/settings-6-icon.png'
import { useEffect } from "react";
import { useRef } from "react";

const OurValuesComponent = () => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    gsap.timeline()
        .fromTo(".setting-1",{
            rotation: 0
          },{
            rotation: 360,
            duration: 10,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
    gsap.timeline()
        .fromTo(".setting-2",{
            rotation: 0
          },{
            rotation: -360,
            duration: 5.65,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
    gsap.timeline()
        .fromTo(".setting-3",{
            rotation: 0
          },{
            rotation: 360,
            duration: 3.49,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
    gsap.timeline()
        .fromTo(".setting-4",{
            rotation: 0
          },{
            rotation: -360,
            duration: 7.36,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
    gsap.timeline()
        .fromTo(".setting-5",{
            rotation: 0
          },{
            rotation: -360,
            duration: 5.6,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
    gsap.timeline()
        .fromTo(".setting-6",{
            rotation: 0
          },{
            rotation: 360,
            duration: 3.46,
            repeat: -1,
            ease: "linear"
          },0)
        .timeScale(1);
  }, []);

  return (
    <div className="value__wrap" ref={ref}>
      <div className="row position-relative">
        <div className="col-12 col-md-6">
          <h2 className="values__title">{t('why_pixpal')}</h2>
          <p className="values__text">{t('why_pixpal_title')}</p>
          <ul className="why-pix__list">
            <li className="why-pix__item"><span>{t('why_pixpal_item_1')}</span></li>
            <li className="why-pix__item"><span>{t('why_pixpal_item_2')}</span></li>
            <li className="why-pix__item"><span>{t('why_pixpal_item_3')}</span></li>
            <li className="why-pix__item"><span>{t('why_pixpal_item_4')}</span></li>
            <li className="why-pix__item"><span>{t('why_pixpal_item_5')}</span></li>
            <li className="why-pix__item"><span>{t('why_pixpal_item_6')}</span></li>
          </ul>
          <p className="why-pix__text">{t('why_pixpal_text_1')}</p>
          <a href="https://myaccount.pixpal.tech/signup" className="values__link btn-anim-dark">{t('open_account')}</a>
        </div>
        <div className="col-12 col-md-6 d-md-block d-none">
          <div className="settings__wrap d-flex align-items-center justify-content-center h-100">
            <img src={settings1} alt="" className="setting-1"/>
            <img src={settings2} alt="" className="setting-2"/>
            <img src={settings3} alt="" className="setting-3"/>
            <img src={settings4} alt="" className="setting-4"/>
            <img src={settings5} alt="" className="setting-5"/>
            <img src={settings6} alt="" className="setting-6"/>

            <img src={icon1} alt="" className="icon-1"/>
            <img src={icon2} alt="" className="icon-2"/>
            <img src={icon3} alt="" className="icon-3"/>
            <img src={icon4} alt="" className="icon-4"/>
            <img src={icon5} alt="" className="icon-5"/>
            <img src={icon6} alt="" className="icon-6"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValuesComponent;
