import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile";
import BannerComponent from "../../components/CompanySupport/Banner/BannerComponent";
import FormComponent from "../../components/CompanySupport/Form/FormComponent";
import AboutCompanyComponent from "../../components/CompanySupport/AboutCompany/AboutCompanyComponent";
import DifferBrokersComponent from "../../components/CompanySupport/DifferBrokers/DifferBrokersComponent";

import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import './CompanySupport.scss';

const StartTrading = () => {
  const [ screenWidth, setScreenWidth ] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  useEffect(() => {
    setScreenWidth(document.documentElement.clientWidth)
  }, []);

  window.addEventListener('resize', event => {
    setScreenWidth(document.documentElement.clientWidth)
  }, false);
  return (
    <div>
      {/* {screenWidth >= 1030 ? <NavBar selPage={'CompanyAndSupport'}/> : <NavBarMobile />} */}
      <div className='nb-desktop'>
        <NavBar selPage={'CompanyAndSupport'} /> 
      </div>
      <div className='nb-mobile'>
        <NavBarMobile selPage={'CompanyAndSupport'}/>
      </div>
      <div className={'container'} >
        <BannerComponent />
        <AboutCompanyComponent />
        <DifferBrokersComponent />
      </div>
      <FormComponent />
      <Footer />
    </div>
  );
};

export default StartTrading;
