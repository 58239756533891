import { useTranslation } from "react-i18next";
import './StandsFor.scss';

const StandsFor = () => {
    const { t } = useTranslation()

    const items = [
      { image: 'highly_profitable.png', text: t('stands_for_card_text_1') },
      { image: 'incredibly_trustful.png', text: t('stands_for_card_text_2') },
      { image: 'client_centered.png', text: t('stands_for_card_text_3') },
      { image: '247_support.png', text: t('affiliate_prog_card_4') },
      { image: 'transparency_of_income.png', text: t('affiliate_prog_card_5') },
    ];

    return (
        <div className='sf-container'>
            <div className='sf-container-header h-1'>
              {t('stands_for_title')}
            </div>
            <div className='sf-container-content'>
                {items.map((item, index) =>
                    <div className={`sf-container-content-item box-${index}`} key={index}>
                        <div className='sf-container-content-item-img'>
                            <img src={`./assets/${item.image}`} alt='icon' />
                        </div>
                        <div className='sf-container-content-item-text content-5'>
                            {item.text}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StandsFor;
