import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';
import Cookies from "js-cookie";
import planet from '../../assets/planet.png';
import planet_mob from '../../assets/planet_mob.png';
import './SsPlanet.scss';

const SsPlanet = () => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');

    const [ screenWidth, setScreenWidth ] = useState(0);
    useEffect(() => {
        setScreenWidth(document.documentElement.clientWidth)
    }, []);
    window.addEventListener('resize', event => {
        setScreenWidth(document.documentElement.clientWidth)
    }, false);

    useEffect(() => {
        const element = ref.current;

        if (screenWidth > 481) {
            gsap.fromTo(
                element.querySelector('.ss-planet-container-planet'),
                {
                    opacity: 0,
                    scale: 0.6,
                    x: -200,
                },
                {
                    opacity: 1,
                    scale: 1,
                    x: 0,
                    duration: 1,
                    delay: .5,
                }
            );
        } else {
            gsap.fromTo(
                element.querySelector('.ss-planet-container-planet'),
                {
                    opacity: 0,
                    scale: 0.6,
                    // x: -200,
                },
                {
                    opacity: 1,
                    scale: 1,
                    // x: 0,
                    duration: 1,
                    delay: .5,
                }
            );
        }
    }, []);

    return (
        <div className='ss-planet-container' ref={ref}>
            <div className='ss-planet-container-content'>
                <div className={`${lang === 'en' ? 'header-en' : 'header-ru'} h-1`}>
                    {t('ss_title')}
                </div>
                <div className='desc content-1'>
                    {t('ss_text')}
                </div>
            </div>
            <img src={screenWidth >= 481 ? planet : planet_mob} alt='planet' className='ss-planet-container-planet' />
        </div>
    );
};

export default SsPlanet;
