import AffiliatesAndPartners from "../pages/AffiliatesAndPartners/AffiliatesAndPartners";
import EduAndTools from "../pages/EduAndTools/EduAndTools";
import MainPage from "../pages/MainPage/MainPage";
import StartTrading from "../pages/StartTrading/StartTrading";
import TradingPlatforms from "../pages/TradingPlatforms/TradingPlatforms";
import AboutPage from "../pages/AboutPage/AboutPage";
import CompanySupport from "../pages/CompanySupport/CompanySupport";
import EarningsCalendar from "../pages/EarningsCalendar/EarningsCalendar";

export const RouteNames = {
    MAIN: '/',
    START_TRADING: '/start-trading',
    TRADING_PLATFORMS: '/trading-platforms',
    EARNINGS_CALENDAR: '/earnings-calendar',
    EDU_AND_TOOLS: '/edu-and-tools',
    AFILLIATES_AND_PARTNERS: '/afilliates-and-partners',
    COMPANY_SUPPORT: '/company-support',
    ABOUT_US: '/about-us',
    PARTNERS: '/partners',
    SUPPORT: '/support',
};

export const routes = [
    { path: RouteNames.MAIN, element: MainPage },
    { path: RouteNames.START_TRADING, element: StartTrading },
    { path: RouteNames.TRADING_PLATFORMS, element: TradingPlatforms },
    { path: RouteNames.ABOUT_US, element: AboutPage },
    { path: RouteNames.TRADING_PLATFORMS, element: TradingPlatforms },
    { path: RouteNames.EDU_AND_TOOLS, element: EduAndTools },
    { path: RouteNames.AFILLIATES_AND_PARTNERS, element: AffiliatesAndPartners },
    { path: RouteNames.COMPANY_SUPPORT, element: CompanySupport },
    { path: RouteNames.ABOUT_US, element: AboutPage },
    { path: RouteNames.ABOUT_US, element: AboutPage },
    { path: RouteNames.EARNINGS_CALENDAR, element: EarningsCalendar },
];
