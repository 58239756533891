import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { table } from '../table.js';
import Search from '../Search/Search';
import Table from '../Table/Table';
import './QuaterlyReporting.scss';
import { useEffect } from 'react';

const QuaterlyReporting = () => {
    const { t } = useTranslation();
    const [ nameFilter, setNameFilter ] = useState('');
    const [ regionFilter, setRegionFilter ] = useState('all');
    const [ allFilter, setAllFilter ] = useState(true); // all = true, week = false
    const [ rows, setRows ] = useState([]);

    useEffect(() => {
        setRows(table);
    }, []);

    useEffect(() => {
        const nowDay = _.toNumber(String(new Date().getDate()).padStart(2, '0'));
        const nowMonth = _.toNumber(String(new Date().getMonth()).padStart(2, '0')) + 1;
        setRows(() => 
            table
                .filter(row => _.toLower(row["Full Name"]).includes(_.toLower(nameFilter)))
                .filter(row => {
                    if (allFilter)
                        return true;
                    const rowDay = _.toNumber(_.words(row["Date"])[0]);
                    const rowMonth = _.toNumber(_.words(row["Date"])[1]);
                    return (rowDay >= nowDay && rowDay <= nowDay + 7 && rowMonth === nowMonth) || (rowMonth === nowMonth + 1 && nowDay - rowDay > 24);
                })
                .filter(row => regionFilter === 'all' || row["Region"] === regionFilter)
        );
    }, [nameFilter, regionFilter, allFilter]);

    return (
        <div className='qr-container'>
            <div className='qr-container-header'>
                {t('quarterly_reporting')}
            </div>
            <div className='qr-container-text'>
                <div className='qr-container-text-item'>
                    <div className='qr-container-text-item-par'>
                        {t('quarterly_reporting_par_1')}
                    </div>
                    <div className='qr-container-text-item-par'>
                        {t('quarterly_reporting_par_2')}
                    </div>
                </div>
                <div className='qr-container-text-item'>
                    {t('quarterly_reporting_par_3')}
                </div>
            </div>

            <div className='qr-container-table'>
                <Search
                    nameFilter = {nameFilter}
                    setNameFilter={setNameFilter}
                    allFilter={allFilter}
                    setRegionFilter={setRegionFilter} 
                    setAllFilter={setAllFilter}
                />
                <Table rows={rows} setRows={setRows} />
            </div>
        </div>
    );
};

export default QuaterlyReporting;
