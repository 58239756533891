import { useState } from 'react';
import { useTranslation } from "react-i18next";
import Tab from './Tab';
import './UtipTrading.scss';

const UtipTrading = () => {
  const { t } = useTranslation()
    const [ selectedTab, setSelectedTab ] = useState(0);
    const tabs = [1, 2, 3, 4];

    return (
        <div className='ut-container'>
            <div className='ut-container-header h-1'>
              {t('utip_trade_title')}
            </div>
            <div className='ut-container-content'>
                {tabs.map((tab) =>
                    <Tab
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tab={tab}
                        key={tab}
                    />
                )}
            </div>
        </div>
    );
};

export default UtipTrading;
