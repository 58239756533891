import './TradingStrategy.scss';
import { useTranslation } from "react-i18next";

const Card = ({ card }) => {
  const { t } = useTranslation()
    const imageURL = `./assets/${card.image}.png`;
    const imageURLwhite = `./assets/${card.image}_white.png`;

    return (
        <a className='strategy-card decoration-none' href='https://myaccount.pixpal.tech/signup'>
            <div className='strategy-card-icon'>
                <img id='image-blue' src={imageURL} alt='icon' />
                <img id='image-white' src={imageURLwhite} alt='' />
            </div>

            <div className='strategy-card-text content-5'>
                {card.text}
            </div>

            <div className='strategy-card-button content-1'>
              {t('learn_more')}
            </div>
        </a>
    );
};

export default Card;
