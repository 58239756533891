import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StartScreen from '../../components/StartScreen/StartScreen';
import TabsCFDS from '../../components/TabsCFDS/TabsCFDS';
import OnlineCFD from '../../components/OnlineCFD/OnlineCFD';
import TradeWithTight from '../../components/TradeWithTight/TradeWithTight';
import TradeAnywhere from '../../components/TradeAnywhere/TradeAnywhere';
import NewGeneration from '../../components/NewGeneration/NewGeneration';
import MarketNews from '../../components/MarketNews/MarketNews';
import TradingStrategy from '../../components/TradingStrategy/TradingStrategy';
import TradeToday from '../../components/TradeToday/TradeToday';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from '../../components/NavBarMobile/NavBarMobile';
import './MainPage.scss';
import classNames from 'classnames';
import Cookies from "js-cookie";

const MainPage = () => {
  const { pathname } = useLocation();
  const lang = Cookies.get('i18next');
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  const handleScroll = () => {
    const position = window.pageYOffset;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [ screenWidth, setScreenWidth ] = useState(0);

  useEffect(() => {
    setScreenWidth(document.documentElement.clientWidth)
  }, []);

  window.addEventListener('resize', event => {
    setScreenWidth(document.documentElement.clientWidth)
  }, false);

  return (
    <div className={`wrapper ${lang === 'pl' && 'inter-all-pl'}`} >
      {/* {screenWidth >= 1030 
        ? <NavBar content='dark' selPage={'StartTrading'} /> 
        : <NavBarMobile content='dark' />
      } */}
      <div className='nb-desktop'>
        <NavBar content='dark' selPage={'StartTrading'} /> 
      </div>
      <div className='nb-mobile'>
        <NavBarMobile content='dark' selPage={'StartTrading'}/>
      </div>
      <StartScreen content={'swiper'} />
      <TabsCFDS content='get_started' />
      <OnlineCFD />
      <TradeWithTight />
      <NewGeneration />
      <MarketNews />
      <TradingStrategy />
      <TradeAnywhere />
      <TradeToday />
      <Footer styles={''} />
    </div>
  )
};

export default MainPage;
