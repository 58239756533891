import TabContent from './TabContent';
import { useTranslation } from "react-i18next";
import './TabsCFDS.scss';



const Tab = ({ selectedTab, setSelectedTab, tab }) => {
    const { t } = useTranslation()
    const tabsInfo = {
        1: {
            header: t('forex_tab_title_1'),
            content: {
                header: t('forex_tab_subtitle_1'),
                description: t('forex_tab_text_1'),
                trading: [
                    { pair: 'GBP / USD', icon: 'cfds_01_01.png', stat: -0.03, graph: 'graph_01_01.png' },
                    { pair: 'USD / JPY', icon: 'cfds_01_02.png', stat: 0.13, graph: 'graph_01_02.png' },
                    { pair: 'EUR / USD', icon: 'cfds_01_03.png', stat: -0.05, graph: 'graph_01_03.png' },
                    { pair: 'AUD / USD', icon: 'cfds_01_04.png', stat: 0.10, graph: 'graph_01_04.png' },
                ]
            }
        },
        2: {
            header: t('forex_tab_title_2'),
            content: {
                header: t('forex_tab_subtitle_2'),
                description: t('forex_tab_text_2'),
                trading: [
                    { pair: 'COPPER-C', icon: 'cfds_02_01.png', stat: 0.23, graph: 'graph_02_01.png' },
                    { pair: 'XAGUSD', icon: 'cfds_02_02.png', stat: 0.13, graph: 'graph_02_02.png' },
                    { pair: 'XAUUSD', icon: 'cfds_02_03.png', stat: 0.05, graph: 'graph_02_03.png' },
                    { pair: 'CL-OIL', icon: 'cfds_02_04.png', stat: 0.15, graph: 'graph_02_04.png' },
                ]
            }
        },
        3: {
            header: t('forex_tab_title_3'),
            content: {
                header: t('forex_tab_subtitle_3'),
                description: t('forex_tab_text_3'),
                trading: [
                    { pair: 'SP500', icon: 'cfds_03_01.png', stat: -0.22, graph: 'graph_03_01.png' },
                    { pair: 'DJ30', icon: 'cfds_03_02.png', stat: -0.21, graph: 'graph_03_02.png' },
                    { pair: 'DAX40', icon: 'cfds_03_03.png', stat: -0.38, graph: 'graph_03_03.png' },
                    { pair: 'SPI200', icon: 'cfds_03_04.png', stat: -0.16, graph: 'graph_03_04.png' },
                ]
            }
        },
        4: {
            header: t('forex_tab_title_4'),
            content: {
                header: t('forex_tab_subtitle_4'),
                description: t('forex_tab_text_4'),
                trading: [
                    { pair: 'Apple', icon: 'cfds_04_01.png', stat: 0.32, graph: 'graph_04_01.png' },
                    { pair: 'Google', icon: 'cfds_04_02.png', stat: 0.07, graph: 'graph_04_02.png' },
                    { pair: 'Facebook', icon: 'cfds_04_03.png', stat: -0.81, graph: 'graph_04_03.png' },
                    { pair: 'Netflix', icon: 'cfds_04_04.png', stat: 0.05, graph: 'graph_04_04.png' },
                ]
            }
        },
    };
    return (
        <div className={`tab ${selectedTab === tab ? 'selected-tab' : ''}`}>
            <div
                className='tab-header content-5'
                onClick={() => setSelectedTab(tab)}
            >{ tabsInfo[tab].header }</div>
            <div className={`tab-content ${selectedTab === tab ? 'show-content' : ''}`}>
                <TabContent content={tabsInfo[tab].content} />
            </div>
        </div>
    );
};

export default Tab;
