import { useTranslation } from "react-i18next";
import crypto_icons from '../../assets/crupto_icons.png';
import arrow_tabs from '../../assets/arrow_tabs.png';
import arrow_tabs_white from '../../assets/arrow_tabs_white.png';
import './UtipTrading.scss';


const Tab = ({ selectedTab, setSelectedTab, tab }) => {
    const { t } = useTranslation()
    const tabsInfo = {
        1: {
            header: t('web_trader'),
        },
        2: {
            header: t('pc_trader'),
            text: t('pc_trader_text'),
        },
        3: {
            header: t('mac_trader'),
            text: t('mac_trader_text'),
        },
        4: {
            header: t('mobilе_trader'),
            text: t('mobilе_trader_text')
        },
    }

    return (
        <div
            className={`
                tab ${selectedTab === tab ? 'selected-tab' : ''}
                ${tab === 4 ? 'border-0' : ''}
            `}
            onClick={() => setSelectedTab(tab)}
        >
            <div className='tab-header h-3'>
                <div className='tab-header-img'>
                    <img id='img-dark' src={`./assets/utip_logo_0${tab}.png`} alt='' />
                    <img id='img-white' src={`./assets/utip_logo_0${tab}_white.png`} alt='' ></img>
                </div>
                <div className='tab-header-text'>
                    {tabsInfo[tab].header}
                </div>
                <div className='tab-header-arrow'>
                    <img
                        src={selectedTab === tab ? arrow_tabs_white : arrow_tabs}
                        alt='arrow'
                    />
                </div>
            </div>
            {selectedTab === 1 ?
                <div className={`tab-content ${selectedTab === tab ? 'show-content' : ''}`}>
                    <div className='tab-content-left'>
                        <div className='tab-content-left-header'>
                            {t('for_forex_title')}
                        </div>
                        <div className='tab-content-left-content'>
                            <ul>
                                <li>{t('for_forex_item_1')}</li>
                                <li>{t('for_forex_item_2')}</li>
                                <li>{t('for_forex_item_3')}</li>
                                <li>{t('for_forex_item_4')}</li>
                                <li>{t('for_forex_item_5')}</li>
                            </ul>
                        </div>
                        <a href='https://myaccount.pixpal.tech/signup' className='decoration-none tab-content-left-btn btn-anim'>
                            {t('trade_now')}
                        </a>
                    </div>
                    <div className='tab-content-right'>
                        <div className='tab-content-right-header'>
                            {t('for_cryptocurrencies')}
                        </div>
                        <div className='tab-content-right-content'>
                            <img src={crypto_icons} alt=''/>
                        </div>
                    </div>
                </div>
                :
                <div className={`tab-content ${selectedTab === tab ? 'show-content' : ''}`}>
                    <div className='tab-content-par'>
                        {tabsInfo[tab].text}
                    </div>
                </div>
            }
        </div>
    );
};

export default Tab;
