import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Trans } from "react-i18next";
import './GetStarted.scss';

const GetStarted = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');

    return (
        <div className='gs-container'>
            <div className='stats-block'>
                <div className='gs-container-stats-item'>
                    <div className='main-text h-2'>0.0</div>
                    <div className='second-text content-2'>{t('spreads_from')}</div>
                </div>
                <div className='gs-container-stats-item'>
                    <div className='main-text h-2'>500:1</div>
                    <div className='second-text content-2'>{t('everage_up_to')}</div>
                </div>
                <div className='gs-container-stats-item'>
                    <div className='main-text h-2'>$500</div>
                    <div className='second-text content-2'><Trans>{t('minimum_deposit')}</Trans></div>
                </div>
                <div className='gs-container-stats-item'>
                    <div className='main-text h-2'>$0</div>
                    <div className={`${lang === 'en' ? 'second-text' : 'second-text-ru'} content-2`}>{t('deposit_fees')}</div>
                </div>
            </div>
            <div className='get-started-block'>
                <div className={`get-started-block-text ${lang !== 'en' ? 'get-started-block-text-lang' : ''} content-3`}>{t('fast_easy')}</div>
                <a href='https://myaccount.pixpal.tech/signup' className='gs-container-button unselectable btn-anim'>{t('get_started')}</a>
            </div>
        </div>
    );
};

export default GetStarted;
