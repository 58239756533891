import Card from './Card';
import { useTranslation } from "react-i18next";
import './TradingStrategy.scss';

const TradingStrategy = () => {
  const { t } = useTranslation()

  const cards = [
    { image: 'lowest_fees', text: t('trading_strategy_text_1') },
    { image: 'broker', text: t('trading_strategy_text_2') },
    { image: 'tradning_tool', text: t('trading_strategy_text_3') },
  ]
    return (
        <div className='ts-container'>
            <div className='header h-1'>
              {t('trading_strategy_title')}
            </div>

            <div className='ts-container-content'>
                {cards.map((card, index) =>
                    <Card card={card} key={index} />
                )}
            </div>
        </div>
    );
};

export default TradingStrategy;
