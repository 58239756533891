import NavBar from '../../components/NavBar/NavBar';
import NavBarMobile from "../../components/NavBarMobile/NavBarMobile";
import BannerComponent from '../../components/AboutUs/Banner/BannerComponent.js';
import OurValuesComponent from '../../components/AboutUs/OurValues/OurValuesComponent';
import WhyPixpalComponent from '../../components/AboutUs/WhyPixpal/WhyPixpalComponent';
import Footer from '../../components/Footer/Footer';
import './AboutPage.scss';
import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import StandsFor from '../../components/StandsFor/StandsFor';

const StartTrading = () => {
  const [ screenWidth, setScreenWidth ] = useState(0);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);
  
  useEffect(() => {
    setScreenWidth(document.documentElement.clientWidth)
  }, []);

  window.addEventListener('resize', event => {
    setScreenWidth(document.documentElement.clientWidth)
  }, false);
  return (
    <div>
      <div className='nb-desktop'>
        <NavBar selPage={'AboutUs'} /> 
      </div>
      <div className='nb-mobile'>
        <NavBarMobile selPage={'AboutUs'} />
      </div>
      <div className={'container'}>
        <BannerComponent />
        <OurValuesComponent />
      </div>
      {/* <WhatPixpalComponent /> */}
      <StandsFor />
      <Footer />
    </div>
  );
};

export default StartTrading;
