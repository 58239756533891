import apple from '../../assets/apple_blue.png'
import { useTranslation } from "react-i18next";
import android from '../../assets/android_blue.png'
import windows from '../../assets/windows_blue.png'
import map from '../../assets/Map.png';
import './RegularBrokers.scss';

const RegularBrokers = () => {
    const { t } = useTranslation()
    return (
        <div className='rb-container'>
            <div className='h-1 header'>
                {t('regular_brok_title')}
            </div>
            <div className='rb-container-content'>
                <div className='info'>
                    <div className='description'>
                        <div className='description-first'>
                            <div className='content-1 description-block' >
                                {t('regular_brok_text_1')}
                            </div>
                            <div className='content-1 description-block' >
                                {t('regular_brok_text_2')}
                            </div>
                        </div>
                        <div className='description-second'>
                            <div className='content-4 download'>
                                <div className='download-platforms'>
                                    <img className='download-platforms-item' src={apple} alt='utip' />
                                    <img className='download-platforms-item' src={android} alt='utip' />
                                    <img className='download-platforms-item' src={windows} alt='utip' />
                                </div>
                                <a href='https://myaccount.pixpal.tech/signup' className='decoration-none trade-now-btn btn-anim-dark'>{t('trade_now')}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='map-glow'>
                    <img id='online-cfd-map' className='map' src={map} alt='map' />
                </div>
            </div>
        </div>
    )
};

export default RegularBrokers;
