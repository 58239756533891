import React, {useEffect, useState} from "react";
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter/AppRouter';
import { ThreeCircles } from "react-loader-spinner";
import './App.scss';


const App = () => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setLoading(true), 1000)
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <BrowserRouter>
    {isLoading ? 
      <AppRouter/>
      :
      <ThreeCircles
        height="70"
        width="70"
        color="rgb(63, 119, 138)"
        wrapperStyle={{marginLeft: 'calc(50vw - 35px)', marginTop: 'calc(50vh - 70px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
        style={{position: 'absolute'}}
      />
    }
    </BrowserRouter>
  );

}

export default App;
